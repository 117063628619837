import { T } from "@/locales";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    customRender: (value, item, index) => index + 1,
  },
  {
    title: T("货品名称"),
    dataIndex: "material_name",
    ellipsis: true,
  },
  {
    title: T("货品编号"),
    dataIndex: "material_number",
  },
  {
    title: T("容器编号"),
    dataIndex: "pallet_number",
  },
  {
    title: T("批次编号"),
    dataIndex: "batch_number",
  },
  {
    title: T("收货数量"),
    dataIndex: "receipt_quantity",
  },
  {
    title: T("单位"),
    dataIndex: "material_unit",
  },
  {
    title: T("计费量"),
    dataIndex: "charge_value",
    key: "charge_value",
  },
  {
    title: T("计费单位"),
    dataIndex: "charge_unit",
    key: "charge_unit",
  },
  {
    title: T("质检状态"),
    dataIndex: "status_display",
    key: "quality_inspection_status",
  },
  {
    title: T("生产日期"),
    dataIndex: "production_date",
  },
];
